import axios from "axios";

const API = "https://auth.api.cloud.torc.tech/v1";

export const getTokens = async (code, clientId, redirectUri) => {
  if (!code) return null;
  const response = await axios.post(`${API}/tokens/code`, {
    client_id: clientId,
    code: code,
    redirect_uri: redirectUri,
  });
  return response;
};
