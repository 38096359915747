/* eslint-disable react/prop-types */

import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";

import * as authApi from "../api/auth-api";
import { processTokens } from "@torc-robotics/npm-okta-oidc-utils";

export default function LoginCallback({ oktaAuth }) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [token] = useState("");

  const redirectUri = `${window.location.origin}/login/callback`;
  // const code = useMemo(() => {
  //   return searchParams.get("code");
  // }, [searchParams]);

  useEffect(() => {
    async function fetchIt() {
      if (token === "") {
        const resp = await authApi.getTokens(
          searchParams.get("code"),
          "0oa8tcb7pkAnLTSCL4x7",
          redirectUri,
        );
        processTokens(oktaAuth, resp.data);
        const afterAuthUrl = localStorage.getItem("afterAuthUrl");
        if (afterAuthUrl && afterAuthUrl !== "") {
          navigate(afterAuthUrl);
        } else {
          navigate("/");
        }
      }
    }
    fetchIt();
    // eslint-disable-next-line
  }, []);
  return null;
}
