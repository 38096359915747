import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { Security } from "@okta/okta-react";
import LoginCallback from "./okta/LoginCallback";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getOktaAuth, oktaAuth } from "./lib/okta";
import SecureRoute from "./okta/SecureRoute";
import { toRelativeUrl } from "@okta/okta-auth-js";
import Hello from "./components/Hello";

const queryClient = new QueryClient();

function PegasusOkta() {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/login", window.location.origin), {
      replace: true,
    });
  };

  const auth = getOktaAuth();
  return (
    <QueryClientProvider client={queryClient}>
      <Security oktaAuth={auth} restoreOriginalUri={restoreOriginalUri}>
        <Routes>
          {/** Put secure routes here */}
          <Route element={<SecureRoute />}>
            <Route path="/" element={<Hello />} exact />
          </Route>
          <Route
            path="/login/callback"
            element={<LoginCallback oktaAuth={oktaAuth} />}
          />
        </Routes>
      </Security>
    </QueryClientProvider>
  );
}

export default function Pegasus() {
  if (!window.location.pathname.includes("login")) {
    localStorage.setItem("afterAuthUrl", window.location.pathname);
  }
  return (
    <Router>
      <PegasusOkta />
    </Router>
  );
}
