import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Outlet } from "react-router-dom";

export default function SecureRoute() {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      oktaAuth.signInWithRedirect();
    }
  }, [oktaAuth, authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <div>Loading...</div>;
  }

  return <Outlet />;
}
