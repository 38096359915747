import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

if (
  process.env.REACT_APP_DEPLOY_ENV === "prod" ||
  process.env.REACT_APP_DEPLOY_ENV === "dev"
) {
  datadogRum.init({
    applicationId: "fdf55e40-9de5-4644-a5a5-aad4f3e40cf8",
    clientToken: "pub49c2b5dff32fc7febb75d079e6767322",
    version: process.env.REACT_APP_VCS_SHORT_REF,
    site: "datadoghq.com",
    service: "pegasus-ui",
    env: process.env.REACT_APP_DEPLOY_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    enableExperimentalFeatures: ["clickmap"],
  });

  datadogLogs.init({
    clientToken: "pub49c2b5dff32fc7febb75d079e6767322",
    site: "datadoghq.com",
    service: "pegasus-ui",
    version: process.env.REACT_APP_VCS_SHORT_REF,
    env: process.env.REACT_APP_DEPLOY_ENV,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ["log", "info", "error"],
    sessionSampleRate: 100,
    useSecureSessionCookie: true,
  });
  datadogLogs.setGlobalContext({ team: "dateng" });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
