import { OktaAuth } from "@okta/okta-auth-js";
import { getOktaAuthConfig } from "@torc-robotics/npm-okta-oidc-utils";

const redirectUri = `${window.location.origin}/login/callback`;
const oktaConfig = getOktaAuthConfig(redirectUri);

export const oktaAuth = new OktaAuth({
  ...oktaConfig,
  responseType: "code",
  pkce: false,
});

export function getOktaAuth() {
  return oktaAuth;
}
